/* You can add global styles to this file, and also import other style files */
@import "ngx-toastr/toastr";

body {
  margin: 0;
}

.vh-100 {
  height: 100vh !important;
}

.ml-5 {
  margin-left: 2em !important;
}

.flex-grow-1 {
  flex-grow: 1;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.failure-field {
  border-color: red;
}
